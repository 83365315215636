import React from "react";
import "../styles/survey_component.css";
import "../styles/reset.css";
// import "../styles/noHeader.css";
import Wrong from "../styles/Images/wrong.png";
import Correct from "../styles/Images/correct.png";
import ISSNLLogo from '../styles/Images/issnl_logo.png'
import imgOne from '../styles/Images/1.png'
import imgTwo from '../styles/Images/2.png'
import imgThree from '../styles/Images/3.png'
import imgFour from '../styles/Images/4.png'
import imgFourTeen from '../styles/Images/14.png'
import imgFiveTeen from '../styles/Images/15.png'
import iconOne from '../styles/Images/4-icon.png'
let data = require("../JSON/questions.json");

export default class Survey_Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionNumber: 0,
      nextQuestion: 0,
      data: {},
      wrong: null,
      correct: null,
      totalCorrect: 0,
      conclusionFromState: false, 
    };
  }
  componentDidMount() {
    this.getData();
  }

  unCheckBoxes = () => {
    document.querySelector("#a").checked = false;
    document.querySelector("#b").checked = false;
    document.querySelector("#c").checked = false;
    document.querySelector("#d").checked = false;
  };
  //conditinal checking limit needs to be checked
  next = (event) => {
    let nextQuestion = this.state.nextQuestion;
    if (this.state.questionNumber <= 27) {
      if (nextQuestion === 0) {
        this.checkMultipleChoice();
        nextQuestion++;
        this.setState({ nextQuestion });
      } else if (nextQuestion === 1 && this.state.questionNumber<27) {
        this.unCheckBoxes();
        let questionNumber = this.state.questionNumber;
        questionNumber++;
        nextQuestion = 0;
        document
          .querySelector(".correctAnserw")
          .classList.toggle("correctAnserw");
        this.setState({ nextQuestion });
        this.setState({ questionNumber });
        this.setState({ wrong: null });
        this.setState({ correct: null });
      }else{
        this.conclusion()
      }
    } else if (this.state.questionNumber === 27) {
      // if on last question
      // this might be going twice on last question to cause bug
      // this.checkMultipleChoice();
      if (nextQuestion === 0) {
        nextQuestion++;
        this.setState({ nextQuestion });
      } else {
        this.conclusion();
      }
    }
  };
  // did they get the question right?
  checkMultipleChoice = (event) => {
    let questionNumber = this.state.questionNumber;
    let data = this.state.data.questions;
    let totalCorrect = this.state.totalCorrect;
    let a = document.querySelector("#a");
    let b = document.querySelector("#b");
    let c = document.querySelector("#c");
    let d = document.querySelector("#d");
    //
    if (data[questionNumber].correct === "a") {
      document.querySelector(`#one`).classList.toggle("correctAnserw");
    } else if (data[questionNumber].correct === "b") {
      document.querySelector(`#two`).classList.toggle("correctAnserw");
    } else if (data[questionNumber].correct === "c") {
      document.querySelector(`#three`).classList.toggle("correctAnserw");
    } else if (data[questionNumber].correct === "d") {
      document.querySelector(`#four`).classList.toggle("correctAnserw");
    }
    //
    let choice = "";
    if (a.checked) {
      choice = "a";
    } else if (b.checked) {
      choice = "b";
    } else if (c.checked) {
      choice = "c";
    } else if (d.checked) {
      choice = "d";
    }
    // needs correct condidtional
    if (choice === data[questionNumber].correct) {
      this.setState({ correct: true });
      // this line broke it even more
      if(totalCorrect<28 ){
      totalCorrect++;
      }
      this.setState({ totalCorrect });
    } else {
      // you are WRONG
      this.setState({ wrong: true });
    }
  };
  getData = (event) => {
    this.setState({ data });
    // mapping data.questions here works
  };
  conclusion = () => {
    // alert("CONCLUSION FUNCTION");
this.setState({wrong:null})
this.setState({correct:null})
    let totalCorrect = this.state.totalCorrect;
    this.setState({ data: false });
    this.setState({ conclusionFromState: true });
    console.log("TOTAL CORRECT" + totalCorrect);
  };
  render() {
    const navCSS='nav{display:none}'
    let qm = this.state.questionNumber;
    let realData = this.state.data.questions;
    let nextQuestion = this.state.nextQuestion;
    let wrong = this.state.wrong;
    let correct = this.state.correct;
    let conclusionFromState = this.state.conclusionFromState;
    let totalCorrect = this.state.totalCorrect;
    // image switch logic
    let spaceMan=document.getElementById('spaceMan')
    if(qm===3 && nextQuestion===1){
      spaceMan.src=imgOne
      spaceMan.classList.remove('animate-scale')
      void spaceMan.offsetWidth;
      spaceMan.classList.add('animate-scale')
    }else if(qm===6 && nextQuestion===1){
      spaceMan.src=imgTwo
      spaceMan.classList.remove('animate-scale')
      void spaceMan.offsetWidth;
      spaceMan.classList.add('animate-scale')
    }else if(qm===9 && nextQuestion===1){
      spaceMan.src=imgThree
      spaceMan.classList.remove('animate-scale')
      void spaceMan.offsetWidth;
      spaceMan.classList.add('animate-scale')
    }else if(qm===13 && nextQuestion===1){
      spaceMan.src=imgFour
      spaceMan.classList.remove('animate-scale')
      void spaceMan.offsetWidth;
      spaceMan.classList.add('animate-scale')
    }
    return (
      <div id="surveyWrapper">
              <style>{navCSS}</style>
        {/* conditional render */}
         {/* {realData && ( */}
         {realData && (
          <section id="surveyContentWrapper" className='margin-left'>
            <h4 id="questionCount">Question {qm + 1}</h4>
            <p id="question" className="space">
              {realData[qm].question}
            </p>
            {/* commented out for debugging purposes */}
            <form>
              <ul>
                <div>
                  <li id="one">
                    <input id="a" name="a" type="checkbox" />
                    <label htmlFor="a">{realData[qm].a}</label>
                  </li>
                  <li id="two">
                    <input id="b" name="b" type="checkbox" />
                    <label htmlFor="b">{realData[qm].b}</label>
                  </li>
                  <li id="three">
                    <input id="c" name="c" type="checkbox" />
                    <label htmlFor="c">{realData[qm].c}</label>
                  </li>
                  <li id="four">
                    <input id="d" name="d" type="checkbox" />
                    <label htmlFor="d">{realData[qm].d}</label>
                  </li>
                </div>
              </ul>
            </form>
            <div className="navButtons">
              <button id="next" onClick={this.next}>
            {/* Next */}
              {nextQuestion === 1 ?<div>Next</div> :<div>Submit</div>}
              </button>
            </div>
            <div className='confirmationContainer'>
            <div id="correctOrWrong">
          {wrong && <img alt="wrong" src={Wrong} className="icon animate-scale" />}
          {correct && <img alt="correct" src={Correct} className="icon animate-scale" />}
          <div id='blurb' className='bottom_space'>
            {nextQuestion === 1 ?<p>{realData[qm].blurb}</p> :<p></p>}
            </div>
        </div>
            </div>
          </section>
          
        )}
        {/* condtional render */}
         {/* {conclusionFromState && (  */}
         {/* MAKE THIS ITS OWN COMPONENTS WITH PROPS FOR VARIABLES */}
         {conclusionFromState &&(
          // NEEDS TO BE TESTED TO SEE IF 25/25 IS POSSIBLE AND NO INCREMENT PROBLEMS WITH STATE
          <div className='grandWrapper'>
            <div className='space sumItUp'>
            <p className='space stat'>{totalCorrect}/28 Great Job! You are a super star!</p>
            <p>Circling the Earth every 90 minutes, the ISS is one of the most complex and innovative laboratories in existence. It provides a unique environment to do experiments in microgravity, test materials and technology in the harsh conditions of space, and view our planet from a powerful vantage point in low Earth orbit. Working together with NASA, the ISS National Lab aims to advance the nation’s leadership in commercial space, pursue groundbreaking science not possible on Earth, and leverage the space station to inspire the next generation.</p>
            </div>
            <ul className='resoursesContainer space'>
              <li className='resListItems'>
                <a href='https://www.issnationallab.org/stem/learn-at-home/' target="_blank">
                  <img className='rokcetIcon' src={imgFiveTeen} alt='rocket'/>
                <p className='learnMoreDescrip'><b className="simpleEyeCatcher">Kids and parents</b>, check out our ISS National Lab “learn at home” content, where you’ll find a bunch of activities you can do together!</p>
                <p className='learnMore'>Learn More</p>
                </a>
                </li>
              <li className='resListItems'>
              <img className='rokcetIcon' src={iconOne} alt='rocket'/>
              <a href='https://www.issnationallab.org/expedition-space-lab/' target="_blank">
                <p className='learnMoreDescrip'><b className='simpleEyeCatcher'>Educators</b>, we have something for you too! Check out Expedition Space Lab, an online tool designed to help you bring space-based STEM learning into the classroom!</p>
                <p className='learnMore'>Learn More</p>
                </a>
              </li>
            </ul>
            <div className='sourcesContainer'>
            <h4 className='headerForSources'>Sources:</h4>
            <ul className='soucresContainerList space'>
              <li><a href='https://www.nasa.gov/feature/facts-and-figures'>NASA (Facts and Figures)</a></li>
              <li><a href='https://www.nasa.gov/centers/marshall/history/eclss.html'>NASA (History)</a></li>
              <li><a href='https://www.nasa.gov/mission_pages/station/structure/elements/space-station-assembly'>NASA (Space Station Assembly)</a></li>
              <li><a href='https://www.issnationallab.org/about/iss-timeline/#:~:text=The%20first%20rudimentary%20station%20was,ever%20developed%3A%20the%20American%20shuttles'>The ISS National Lab</a></li>
              <li><a href='https://en.wikipedia.org/wiki/Zarya_'>Wikipedia</a></li>
              <li><a href='https://starlust.org/how-long-does-it-take-to-get-to-the-iss-from-earth/'>Starlust.org</a></li>
            </ul>
            </div>
          </div>
        )}
        {!conclusionFromState &&
        <div className='logo'>
      <img src={ISSNLLogo} alt='ISSNL Logo'/>
    </div>
  }
   <div id='spaceManContainer'>
    <img className='animate-scale' id='spaceMan' src={imgFourTeen} alt='Space Man'/>
    </div>
      </div>
    );
  }
}
