import React from "react";
import "../styles/reset.css";
import "../styles/survey_component.css"
import "../styles/App.css"
import CoolestDude from '../styles/Images/4-icon.png'

export default class Intro_Quiz_Screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }
  componentDidMount() {
  }

  render() {
    const navCSS='nav{display:none}'
    return (
      <div id="surveyWrapper" className='introSurveyWrapper'>
        <div id="surveyContentWrapper">
       <h2 className='space introHeader'>What do you know about the International Space Station? Take the Quiz!</h2>
       <p className='space'>How well do you know the International Space Station (ISS)? Blast off into low Earth orbit with this quiz all about the orbiting laboratory while you wait to explore the station in breathtaking virtual reality with the Infinite. </p>
       <div className='navButtons'>
      <a href='/quiz'><button id='next'>Start Quiz</button></a>
      <span className='CTA_Rocket_Intro'><img src={CoolestDude}/></span>
      <style>{navCSS}</style>
      </div>
      </div>
      </div>
    );
  }
}
