import React from "react";
import "../styles/reset.css";
// import "../styles/survey_component.css"
// import "../styles/App.css"
import "../styles/landing.css";
import testImg from "../styles/Images/landingHeroImg.jpg";
import SSELogo from "../styles/Images/SSE_Logo.png";
import WindowSeat from "../styles/Images/ISSNL.jpg";
import SciencePic from "../styles/Images/spaceWalk.jpg";
import ISS_QUIZ from '../styles/Images/take_the_quiz.png'
import Ex_ISS from '../styles/Images/exterior_iss.jpg'
export default class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }
  async componentDidMount() {
    // run async function
    await fetch(
      "https://api-us-east-1.hygraph.com/v2/cl8j781qu0k4501tdcjogg8kp/master",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query: `
                query {
                    landingPages{
               pageTitle
               pageSubtitle
               introParagraph
               header1
               paragraphText1
               header2
               paragraphText2
               imageCaption1
               imageCaption2
               imageCaption3
               paragraphTextAtBottomOfPage
                  }
                } 
                `,
        }),
      }
    )
      .then((data) => {
        // if (!res.ok) return Promise.reject(res);
        return data.json();
      })
      .then((data) => {
        data = data.data.landingPages[0];
        this.setState({ data });
      });
  }

  render() {
    let data;
    if (this.state.data) {
      data = this.state.data;
    }
    return (
      <div id="landingWrapper">
        {/* header image area */}
        <div id="hero_img_area"></div>
        <div className="wrapper_with_all_content">
          {data && (
            <section id="landing_main_section">
              <h2 className="landingSuperHeader">{data.pageTitle}</h2>
              <h6 className="landingHeader">{data.pageSubtitle}</h6>
              {/* ahve text wrap around images */}
              <div className="landing_Content">
                <p>{data.introParagraph}</p>
                <div className="floatRight imgOne">
                  <img className="contentImg" src={WindowSeat} alt='Close up of the ISS orbiting Earth' />
                  <p className='caption'>The International Space Station (ISS) houses a national laboratory similar to laboratories here on Earth, yet offering specific features of the space environment, including a unique vantage point, extreme conditions, and microgravity (weightlessness). Image Credit: NASA</p>
                </div>
                <h5 className="landingHeader">{data.header1}</h5>
                <p>{data.paragraphText1}</p>
                <p>Following the success of “Space Explorers: The ISS Experience,” Felix & Paul Studios sought to take the show on the road, producing Space Explorers: The Infinite, the world’s largest interactive and immersive experience centered on the space station. Space Explorers: The Infinite is a traveling exhibit that take guests on a 60-minute journey as they roam freely inside a full-scale 3D replica of the International Space Station and float in space alongside the astronauts. After debuting in Houston, Space Explorers: The Infinite will finish the year in San Francisco, with plans to travel to other major U.S. cities moving forward!</p>
                <div className="floatLeft">
                  <img className="contentImg" src={SciencePic} alt='NASA astronaut installs MISSE '/>
                  <p className='caption'>A NASA astronaut installs MISSE, a facility mounted outside the space station that enables the testing of materials and components in the extreme environment of space. Image Credit: NASA</p>
                </div>
                <h5 className="landingHeader">{data.header2}</h5>
                <p>{data.paragraphText2} Go to <a href='https://www.issnationallab.org/' className='color_blue'target='_blank'>www.ISSNationalLab.org</a> to learn more about how the ISS National Lab is enabling access and opportunity for innovative research in low Earth orbit.  
</p>
                <section id="landingConclusion">
                  <h1 className="landingHeader">About the ISS National Lab</h1>
                  <p>{data.paragraphTextAtBottomOfPage}</p>
                 <div id='visitUs'> <p>Visit us at <a href='https://www.issnationallab.org/' target='_blank'>www.ISSNationalLab.org.</a></p></div>
                </section>
              </div>
            </section>
          )}
          {/* sidebar */}
          <section id="sidebar">
            <ul id="sidePanel">
              <h4 className="landingHeader">Related Resources</h4>
              <a href="/intro">
                <li>
                  <img src={ISS_QUIZ} className="sidePanelImg" alt="Take the Cosmic Quiz" />
                  <h3 className="landingSubHeader">The Cosmic Quiz</h3>
                  <p id="visitUs">
                  Think you know everything there is to know about the International Space Station? Take the <a href='/intro'>Cosmic Quiz</a> and find out! 
                  </p>
                </li>
              </a>
              <li>
                <a href='https://www.issnationallab.org/' target='_blank'>
                <img src={Ex_ISS} className="sidePanelImg" alt="ISS orbiting the earth" />
                <h3 className="landingSubHeader">The ISS National Laboratory</h3>
                <p id='visitUs'>
                Did you know that there’s a fully functional lab on the International Space Station? Researchers from all over the country have an opportunity to send their experiments to space. <a href='https://www.issnationallab.org/'>Learn more </a>about the ISS National Lab!
                </p>
                </a>
              </li>
              <li>
              <a href='https://www.issnationallab.org/stem/' target='_blank'>
                <img id='SSELogo' src={SSELogo} className="sidePanelImg" alt="Space Station Explorer Logo" />
                <h3 className="landingSubHeader">Space Station Explorers</h3>
                <p id='visitUs'>
                There are all kinds of STEM programs that take advantage of the International Space Station, a truly unique educational tool, to engage students of all ages. <a href='https://www.issnationallab.org/stem/'>Check out</a> the ISS National Lab partner programs!
                </p>
                </a>
              </li>
              <li>
                <a href='https://www.issnationallab.org/iss360/astronaut-life-infinite/' target='_blank'>
                <img src={testImg} className="sidePanelImg" alt="Night sky with shooting stars" />
                <h3 className="landingSubHeader">Experiencing The Infinite</h3>
                <p id='visitUs'>
                Imagine putting on a virtual reality (VR) headset and finding yourself floating in space. This is the Infinite Experience. <a href='https://www.issnationallab.org/iss360/astronaut-life-infinite/'>Read more</a> about this ISS National Lab-sponsored experience!
                </p>
                </a>
              </li>
            </ul>
          </section>
        </div>
        {/* end of grand wrapper */}
      </div>
    );
  }
}
