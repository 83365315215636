import './styles/App.css';
import Survey_Body from './Components/survey_body'
import Intro_Quiz_Screen from './Components/intro_quiz_screen';
import './styles/reset.css'
import Landing from './Components/landing'
import ISSLogo from './styles/Images/issnl_logo_black.png'
import CoolestDude from './styles/Images/4-icon.png'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

function App() {
  return (
    <div className="App">
       <nav>
        <div className='navContainer'>
          <img className='navLogo' src={ISSLogo}/>
          <button><a id='call_to_action_link' href='/intro'>TAKE THE COSMIC QUIZ<span className='CTA_Rocket'><img id='rocketImg'src={CoolestDude}/></span></a></button>
          </div>
        </nav>
              <div id='moreWrappers'>
             
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landing/>}></Route>
        <Route path='/intro' element={<Intro_Quiz_Screen/>}></Route>
        <Route path='/quiz' element={<Survey_Body/>}></Route>
      </Routes>
      </BrowserRouter>
      </div>
     {/* <Survey_Body/> */}
    
   
    {/* <div className='folder_img'>
      <img src={folder} alt='background manilla folder'/>
    </div> */}
    </div>
  );
}

export default App;
